@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  button,
  label {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
  }
  html,
  body {
    user-select: none;
    overscroll-behavior: none;
  }
  input {
    border: 2px solid #000;
  }
}

@layer components {
  #bitmap {
    image-rendering: pixelated;
  }
}
